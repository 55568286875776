<template>
    <hb-modal v-model="dialog" size="large" :title="isEditMode ? 'Update Task' : 'Create Task'" show-help-link>
      <template v-slot:content>
        <hb-form label="Task Type" description="Select the appropriate task type and subtype. If the subtask type is “other” choose a title for the task." required>
            <HbSelect
                placeholder="Select Type"
                v-model="taskData.type"
                :items="taskCategories.types"
                item-text="name"
                item-value="id"
                @change="taskBucketChangeEvent"
                v-validate="'required'"
                id="task_type"
                name="task_type"
                data-vv-name="task_type" 
                data-vv-as="Type" 
                :error="errors.has('task_type')"
            />
            <!-- <HbSelect
                v-if="taskData.type"
                placeholder="Select Subtype"
                v-model="taskData.subType"
                :items="taskSubTypes[taskData.type]"
                item-text="name"
                item-value="id"
            /> -->

            <HbTextField
                v-if="taskData.type && taskData.subType"
                v-validate="'required|max:45'"
                placeholder="Enter task title"
                v-model="taskData.title"
                id="task_title"
                name="task_title"
                data-vv-name="task_title" 
                data-vv-as="Title" 
                :error="errors.has('task_title')"
            />
        </hb-form>

        <hb-form label="Assign to" description="Select one or multiple role(s) AND/OR user(s) to assign this task to." required>
            <!-- <HbTextField
                v-validate="'max:45'"
                placeholder="Search roles or users"
                id="task"
                name="task"
                data-vv-scope="todo"
                data-vv-as="Task Name"
                :error="errors.collect('todo.task').length > 0"
            >
            <template v-slot:prepend-inner>
                <hb-icon small>mdi-magnify</hb-icon>
            </template>
        </HbTextField> -->
            <hb-combobox
                v-model="taskData.assignTo"
                :items="taskAssignees"
                item-text="name"
                item-value="id"
                append-icon=""
                prepend-inner-icon="mdi-magnify"
                label="Search roles or users"
                v-validate="'required'"
                id="task_assignees"
                name="task_assignees"
                data-vv-name="task_assignees"
                data-vv-as="Assign to" 
                :error="errors.has('task_assignees')"
                :search-input.sync="searchText.assignTo"
                @blur="clearSearchText('assignTo')"
                @keydown.enter="clearSearchText('assignTo')"
            >
            </hb-combobox>
        </hb-form>

        <hb-form label="Task Association" description="Select the object this task is for." required full>

            <v-col v-if="isEditMode" class="pa-0">
                <hb-radio-group :value="true" light>
                    <div class="d-flex">
                        <hb-radio :value="true" :disabled="true" />
                        <span class="hb-font-body">{{ taskData.association === 3 ? 'Tenant' : taskData.association === 2 ? 'Space' : taskData.association === 1 ? 'Property' : 'Nothing' }}</span>
                    </div>
                </hb-radio-group>
                <div v-if="taskData.association && taskData.selectedProperties && taskData.selectedProperties.length">
                    <p class="hb-text-light mb-3 mt-2">
                        Property or properties this task is associate to:
                    </p>
                    <hb-chip :readonly="true">{{ (taskData.selectedProperties[0]?.number ? taskData.selectedProperties[0].number + ' - ' : '') + (taskData.selectedProperties[0]?.city ? taskData.selectedProperties[0].city + ' - ' : '') + taskData.selectedProperties[0]?.name }}</hb-chip>
                </div>
                <div v-if="taskData.association === 2">
                    <p class="hb-text-light mt-4 mb-3">
                        Space this task is associate to:
                    </p>
                    <hb-chip :readonly="true">{{ taskData.associations[0].number }}</hb-chip>
                </div>
                <div v-if="taskData.association === 3">
                    <p class="hb-text-light mt-4 mb-3">
                        Tenant this task is associate to:
                    </p>
                    <hb-chip :readonly="true">{{ taskData.associations[0].first + ' ' + taskData.associations[0].last }}</hb-chip>
                </div>
            </v-col>
            <div v-else>
                <hb-radio-group row hide-details v-model="taskData.association" :value="2">
                    <hb-radio label="Nothing" :value="0"></hb-radio>
                    <hb-radio label="Property" :value="1"></hb-radio>
                    <hb-radio label="Space" :value="2"></hb-radio>
                    <hb-radio label="Tenant" :value="3"></hb-radio>
                </hb-radio-group>
    
                <v-col class="px-0 pt-4" v-if="taskData.association">
                    <p class="hb-text-light">
                        <span v-if="taskData.association === 1">
                            Select property or properties to assign this property task to. If multiple properties are selected, the system will create a task for each associated property.
                        </span>
                        <span v-if="taskData.association === 2">
                            Select property or properties to search spaces from. If multiple spaces are selected, the system will create a task for each associated space with associated property.
                        </span>
                        <span v-if="taskData.association === 3">
                            Select property or properties to search tenants from. If multiple tenants are selected, the system will create a task for each associated space with associated property.
                        </span>
                    </p>
                    <hb-combobox class="mt-4" 
                        v-model="taskData.selectedProperties" 
                        v-validate="taskData.association !== 3 ? 'required' : ''" 
                        :items="propertyItems" 
                        id="primary_properties"
                        name="primary_properties" 
                        label="Select Properties"
                        data-vv-name="primary_properties" 
                        data-vv-as="Properties"
                        :error="errors.has('primary_properties')" 
                        item-value="id" 
                        item-text="name"
                        :search-input.sync="searchText.selectedProperties"
                        @blur="clearSearchText('selectedProperties')"
                        @keydown.enter="clearSearchText('selectedProperties')" 
                        return-object />
    
                    <p class="hb-text-light pt-1">
                        <span v-if="taskData.association === 2">Select space(s) to associate this task to.</span>
                        <span v-if="taskData.association === 3">Select tenant(s) to assign this task to. If multiple tenants are selected, the system will create a task for each associated tenant.</span>
                    </p>
    
                    <hb-combobox
                        v-if="taskData.association === 2 || taskData.association === 3"
                        v-model="taskData.associations"
                        :items="associationList"
                        @update:search-input="searchSpaceorTenatEvent"
                        item-text="name"
                        :loading="isFetching"
                        item-value="id"
                        :label="'Select ' + (taskData.association === 2 ? 'space(s)' : 'tenant(s)' )"
                        class="mt-4"
                        v-validate="'required'"
                        id="task_association"
                        name="task_association"
                        data-vv-name="task_association" 
                        data-vv-as="Association"
                        :error="errors.has('task_association')"
                        :search-input.sync="searchText.associations"
                        @blur="clearSearchText('associations')"
                        @keydown.enter="clearSearchText('associations')"
                    >
                    <template v-slot:item="{item, attrs}">
                        <v-list-item-action> 
                            <v-icon v-if="attrs.inputValue">check_box</v-icon> 
                            <v-icon v-else :color="item.disabled ? '#DFE3E8' : '#9b9b9b'">check_box_outline_blank</v-icon>
                        </v-list-item-action>
                        <v-list-item-content> 
                            <v-list-item-title v-if="taskData.association === 2"> 
                                <hb-icon small>mdi-facility-custom-3</hb-icon> 
                                <span class="top--2px">{{ item.name }}</span>
                                <hb-icon small>mdi-facility-custom-2</hb-icon> 
                                <span class="top--2px">{{ item.property_number }}</span>
                            </v-list-item-title> 
                            <v-list-item-title v-else>
                                <span class="top--2px pr-0">{{ item.name }}</span>, 
                                <span>
                                    <hb-icon small>mdi-facility-custom-3</hb-icon> 
                                    <span class="top--2px">{{ item.unit_number }}</span>
                                </span>
                            </v-list-item-title>
                        </v-list-item-content> 
                    </template>
                </hb-combobox>
                </v-col>
            </div>
        </hb-form>

        <hb-form label="Details" description="Provide details about the task." full>
            <hb-textarea
                placeholder="Enter Task details"
                v-model="taskData.details"
            ></hb-textarea>
        </hb-form>

        <hb-form label="Due Date" description="Select due date or mark as hot task. A hot task has a due date of today and has a timer that starts counting up from now.">
            <HbSwitch
                label="Hot Task"
                v-model="taskData.hotTask"
            />

            <hb-date-picker
                v-if="!taskData.hotTask"
                label="Select Date"
                :disabled="taskData.hotTask ? true : false"
                :readonly="taskData.hotTask ? true : false"
                v-model="taskData.dueDate"
                :todo="true"
                :clearable="true"
                clear-icon="mdi-close-circle"
                :solo="false"
                :single-line="true"
                data-vv-as="Due by"
                name="due_date"
                v-validate="'required'"
                data-vv-scope="todo"
                :min="new Date().toISOString()"
                :error="errors.collect('todo.due_date').length > 0"
            ></hb-date-picker>
        </hb-form>

        <hb-form label="Add Images or Videos" description="Insert up to 20 images max at 10 megabytes each image." full>
            <hb-media-viewer
                v-if="taskData.mediaFiles.length"
                uploader
                @upload="handleUploadedFiles"
                @delete="handleDeleteFile"
                v-model="mediaViewer"
                grid
                grid-padding-off
            >
                <template v-slot:slides>
                    <slide
                        v-for="(slide, i) in taskData.mediaFiles"
                        :key="'slide_' + i"
                        :video="slide.video"
                        :video_src="slide.video_src"
                        :src="slide.src"
                    />
                </template>
            </hb-media-viewer>
            <hb-empty-state v-else>
                <div class="pa-3 image-upload" @drop.prevent="dropFilesEvent" @dragover.prevent="" @dragleave="">
                    <span>
                        <HbFileInput accept="image/*,video/*" style="display: none;" @change="validateMediaFiles" multiple ref="tempFileInput" class="mb-3" />
                        <label for="file-input">
                            <div class="upload-content">
                                <hb-icon class="pb-2">mdi-image</hb-icon>
                                <p class="upload-text-color pt-1 mb-2"><a class="click-to-upload" @click="$refs.tempFileInput.$el.querySelector('input').click()" >Click to upload</a> or drag and drop</p>
                                <p class="upload-text-color-small">(up to 10 megabytes)</p>
                            </div>
                        </label>
                    </span>
                </div>
            </hb-empty-state>
        </hb-form>

        <hb-form label="Attachment" description="Insert up to 10 attachments">
            <v-file-input
                accept=".pdf"
                multiple
                v-model="taskData.attachments"
                @change="validateFiles"
            >
                <template v-slot:selection="{ text, file }">
                    <v-chip
                        v-if="file"
                        class="ma-1"
                        color="primary"
                        outlined
                        close
                        small
                    >
                        {{ text }}
                    </v-chip>
                </template>
            </v-file-input>
        </hb-form>

        <hb-form label="Location" :divider="false" description="Enter the location where this task is done.">
            <hb-radio-group row v-model="taskData.location" hide-details>
                <hb-radio label="Inside" :value="0"></hb-radio>
                <hb-radio label="Outside" :value="1"></hb-radio>
            </hb-radio-group>
        </hb-form>

      </template>
      
      <template v-slot:actions>
        <hb-btn
          color="primary"
          :disabled="isLoading($options.name)"
          :loading="isLoading($options.name)"
          @click="saveTask">{{ isEditMode ? 'Save' : 'Create' }}
        </hb-btn>
      </template>
    </hb-modal>
  </template>


<script>

import { mapGetters, mapActions } from 'vuex';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import api from '../../../assets/api.js'
import moment from 'moment';

export default {
    name: 'CreateTaskModal',
    mixins: [ notificationMixin ],
    props: {
        value: {
            type: Boolean, 
            default: false
        },
        taskDetails: {
            type: Object, 
            default: null
        },
        taskSubTypes: {
            type: Object,
            default: {}
        },
        taskAssignees: {
            type: Array,
            default: []
        },
        fetchTasks: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        'hb-date-picker': () => import('../../assets/HummingbirdDatepicker.vue')
    },
    data() {
        return {
            taskCategories: {
                types: [
                    {
                        id: 'lead',
                        name: 'Lead'
                    },
                    {
                        id: 'delinquecy',
                        name: 'Delinquency'
                    },
                    {
                        id: 'operations',
                        name: 'Operations',
                        // disabled: true
                    },
                    {
                        id: 'maintenance',
                        name: 'Maintenance',
                        // disabled: true
                    }
                ]
            },
            taskTypes: ['Lead', 'Delinquency', 'Operations', 'Maintenance'],
            associationList: [],
            assignTo: [],
            taskData: {
                type: '',
                subType: '',
                title: '',
                assignTo: [],
                association: 0,
                selectedProperties: [],
                details: '',
                hotTask: false,
                dueDate: '',
                mediaFiles: [],
                attachments: [],
                location: 1,
                associations: [],
                startDate: ''
            },
            isFetching: false,
            mediaViewer: false,
            searchText: {}
        }
    },
    created() {
        if (this.filtered.length === 1) {
            this.taskData.selectedProperties = this.filtered;
        }
    },
    computed: {
        ...mapGetters({
            filtered: 'propertiesStore/filtered'
        }),
        dialog: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        propertyItems() {
            return this.filtered.map(p => {
                return {
                    id: p.id,
                    name: (p.number ? p.number + ' - ' : '') + (p.city ? p.city + ' - ' : '') + p.name,
                    number: p.number,
                    city: p.city,
                    gds_id: p.gds_id,
                    disabled: false,
                }
            })
        },
        isEditMode() {
            return this.taskDetails?.id ? true : false
        }
    },
    watch: {
        taskDetails(params) {
            if (params) {
                this.taskData = JSON.parse(JSON.stringify(params));
            } else {
                this.taskData = {
                    type: '',
                    subType: '',
                    title: '',
                    assignTo: [],
                    association: 0,
                    selectedProperties: [],
                    details: '',
                    hotTask: false,
                    dueDate: '',
                    mediaFiles: [],
                    attachments: [],
                    location: 1,
                    associations: [],
                    startDate: ''
                }
            }
        },
        'taskData.association': {
            handler: function(params) {
                this.taskData = {
                    ...this.taskData,
                    selectedProperties: [],
                    associations: []
                };
                this.associationList = [];
            }
        }
    },
    methods: {
        dropFilesEvent(event) {
            this.validateMediaFiles(event.dataTransfer.files);
        },
        async validateMediaFiles(event) {
            this.uploading = true;
            let files = event?.target?.files ? event.target.files : event;
            if (files.length > 20) {
                this.showMessageNotification({ description: 'Maximum 20 files are allowed!'})
                if(event.target) event.target.value = [];
                else event = [];
                return;
            }
            let sizeCheck = Object.keys(files).find(item => files[item].size > 10000000);
            if (sizeCheck) {
                this.showMessageNotification({ description: 'Files must be smaller than 10MB'})
                if(event.target) event.target.value = [];
                else event = [];
                return;
            }
            // this.taskData.mediaFiles = files;
            let typeCheck = Object.keys(files).find(item => !files[item].type.includes('image') && !files[item].type.includes('video'));
            if (typeCheck) {
                this.showMessageNotification({ description: 'Selected files must be of type image or video'});
                if(event.target) event.target.value = [];
                else event = [];
                return;
            }
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let fileType = file.type.split("/")[0]
                let base64 = await this.readFile(file);
                let videoThumbnail = '';
                if (fileType === 'video') {
                    videoThumbnail = await this.getVideoThumbnail(file)
                }
                console.log('videoThumbnail :>>', videoThumbnail)
                this.taskData.mediaFiles.push({
                    id: '',
                    video: fileType === 'video' ? true : false,
                    video_src: base64,
                    src: fileType === 'video' && videoThumbnail ? videoThumbnail : base64,
                    thumbnail: videoThumbnail,
                    original_file: file
                })
            }
            if(event.target) event.target.value = [];
            else event = [];
        },
        readFile(file){
            console.log('file :>> ', file)
            try {
                return new Promise((resolve, reject) => {
                    var fr = new FileReader();  
                    fr.onload = () => {
                        resolve(fr.result)
                    };
                    fr.onerror = reject;
                    fr.readAsDataURL(file);
                });
            } catch (error) {
                this.showMessageNotification({ description: error })
            }
        },
        async getVideoThumbnail(params) {
            return new Promise((resolve, reject) => {
                const video = Object.assign(document.createElement("video"), {
                    src: URL.createObjectURL(params),
                    muted: true,
                    crossOrigin: "anonymous",
                    autoplay: true
                });

                video.onloadeddata = () => {
                    const canvas = Object.assign(document.createElement("canvas"), {
                        width: 300,
                        height: (video.videoHeight / video.videoWidth) * 300
                    });
                    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
                    resolve(canvas.toDataURL("image/png"));
                };

                video.onerror = reject;
            });
        },
        validateFiles(event) {
            console.log('files :>> ', event, event?.target)
            let files = event?.target?.files ? event.target.files : event;
            if (!files || files.length === 0) return;
            const allowedTypes = ["application/pdf"];
            const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));
            if (invalidFiles.length > 0) {
                this.showMessageNotification({ description: 'Only PDF files are allowed!' });
                if(event.target) event.target.value = [];
                else event = [];
                return;
            }
            // this.taskData.attachments = files;
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                this.taskData.attachments.push({
                    original_file: file
                })
            }
        },
        blurredEvent(){
            console.log('blurredEvent :>> ');
        },
        searchSpaceorTenatEvent: _.debounce(async function(params, force) {
            if(!params || !params.trim()) return;
            if (this.taskData.association !== 3 && !this.taskData.selectedProperties.length) {
                this.showMessageNotification({type: 'error', description: "Please select property first"});
                return;
            }
            this.isFetching = true;
            try {
                let payload = {
                    "search": params.trim().toLowerCase(),
                    "property_ids": this.taskData.association === 3 && !this.taskData.selectedProperties.length ? this.filtered.map(item => item.id).filter(item => item) : this.taskData.selectedProperties.map(item => item.id).filter(item => item)
                }
                let response = await api.post(this, api.PROPERTIES + `/search/${this.taskData.association === 2 ? 'spaces' : 'tenants'}`, payload);
                console.log('response :>> ', response);
                this.associationList = response.data;
            } catch (error) {
                console.log('searchSpaceorTenatEvent -> error :>>', error)
            } finally {
                this.isFetching = false;
            }
        }, 1000),
        async saveTask() {
            const validationStatus = await this.$validator.validateAll();
            if (!validationStatus) return;
            let associate;
            switch (this.taskData.association) {
                case 1:
                    associate = 'property'
                    break;
                case 2:
                    associate = 'space'
                    break;
                case 3:
                    associate = 'contact'
                    break;
                default:
                    associate = 'nothing'
                    break;
            }
            let currentDateTime = moment().format("YYYY-MM-DD HH:mm:ss")
            let payload = {
                task_type_id: this.taskData.subType,
                title: this.taskData.title,
                details: this.taskData.details,
                due_date: this.taskData.hotTask ? currentDateTime : this.taskData.dueDate,
                start_date: this.taskData.startDate ? this.taskData.startDate : currentDateTime,
                hot_task: this.taskData.hotTask ? 1 : 0,
                task_location: this.taskData.location ? 'outside' : 'inside',
                status: this.isEditMode ? this.taskData.status : 'pending',
                Assignees: this.taskData.assignTo.filter(item => item && item.id).map(item => {
                    return {
                        type: item.is_role ? 'role' : 'user',
                        id: item.id
                    }
                }),
                association_type: associate,
                associations: associate === 'nothing' ? [] : associate === 'property' ? this.taskData.selectedProperties.filter(item => item && item.id).map(item => { return { id: item.id }}) : this.taskData.associations.filter(item => item && item.id).map(item => { return { id: item.id }})
            }

            if (this.isEditMode) {
                let prev_images = [...this.taskData.mediaFiles.filter(item => item.upload_id).map(item => {return { id: item.upload_id}}), ...this.taskData.attachments.filter(item => item.upload_id).map(item => {return { id: item.upload_id}})];
                if (prev_images && prev_images.length) {
                    payload = {
                        ...payload,
                        prev_images: prev_images
                    }
                }
            }
            try {
                // let response = await api.post(this, api.TASKS_CENTER, payload);
                await api.postFile(this,  api.TASKS_CENTER + (this.isEditMode ? this.taskData.id : ''), payload, [...this.taskData.mediaFiles.filter(item => !item.upload_id).map(item => item.original_file), ...this.taskData.attachments.filter(item => !item.upload_id).map(item => item.original_file)], null, this.isEditMode);
                this.dialog = false;
                this.fetchTasks(true);
            } catch (error) {
                this.showMessageNotification({type: 'error', description: error});
            }
        },
        handleUploadedFiles(params) {
            this.validateMediaFiles(params);
        },
        handleDeleteFile(params) {
            let index = params.key.split("slide_")[1];
            this.taskData.mediaFiles.splice(index, 1)
        },
        taskBucketChangeEvent(params) {
            this.taskData.subType = this.taskSubTypes[params].find(t => t.slug === 'other').id;
        },
        clearSearchText(params) {
            if(this.searchText[params]) this.searchText[params] = '';
        }
    }
}
</script>
<style scoped lang="scss">
    .empty-image-box {
        width: 125px;
        height: 125px;
        border: 1.5px dashed #C4CDD5;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 4px;

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 23.8px;
            text-align: center;
            text-decoration-line: underline;
            text-decoration-style: solid;
            color: #00848E;
        }
    }

    .img-boxes {
        display: flex;
        // justify-content: space-between;
    }

    .image-box {
        width: 125px;
        height: 125px;
        overflow: hidden;
        position: relative;

        img, video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.cover-image:after {
            content: "Cover Image";
            position: absolute;
            left: 50%;
            bottom: 7px;
            color: white;
            font-size: 12px;
            font-weight: 500;
            line-height: 16px;
            width: 100%;
            transform: translateX(-30%);

        }

        .img-box-last {
            position: absolute;
            left: 0;
            top: 0;
            width: 125px;
            height: 125px;
            // background-color: rgba(0, 0, 0, 0.5);
            background: #363636B2;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
        }
    }

    .top--2px {
        position: relative;
        top: -2px;
        padding: 0 4px;
        padding-right: 8px;
    } 

    .image-upload input[type="file"] {
    display: none;
    } 
</style>